<!--
 * @Author: filename
 * @Description: 学历信息-编辑
-->

<template>
    <div class="common-part">
        <el-form :model="ruleForm" ref="ruleForm" label-width="100px">
            <el-row>
                <el-col :span="12">
                    <el-form-item
                        label="学校全称："
                        :rules="{ required: true, message: '请输入学校全称', trigger: 'change' }"
                        prop="college"
                    >
                        <SchoolSelect v-model="ruleForm.college" :product-id="1" placeholder="如是世界百强，则输入匹配选择" style="width: 100%;" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="授课形式："
                        prop="type"
                    >
                        <el-select v-model="ruleForm.type" placeholder="请选择" style="width: 100%;">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="入学年月："
                        :rules="{ required: true, message: '请选择毕业年月', trigger: 'change' }"
                        prop="admission_time"
                    >
                        <el-date-picker
                            style="width: 100%;"
                            v-model="ruleForm.admission_time"
                            type="month"
                            value-format="yyyy-MM"
                            :picker-options="ruleForm.pickerAdmissionTime"
                            placeholder="入学年月"
                            @focus="handleGraduateChange(ruleForm.graduate_time)">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="毕业年月："
                        :rules="{ required: true, message: '请选择毕业年月', trigger: 'change' }"
                        prop="graduate_time"
                    >
                        <el-date-picker
                            style="width: 100%;"
                            v-model="ruleForm.graduate_time"
                            type="month"
                            value-format="yyyy-MM"
                            :picker-options="ruleForm.pickerGraduateTime"
                            placeholder="毕业年月"
                            @focus="handleAdmissionChange(ruleForm.admission_time)">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                        label="专业："
                        :rules="{ required: true, message: '请输入专业', trigger: 'change' }"
                        prop="professional"
                    >
                    <el-autocomplete
                        v-model="ruleForm.professional"
                        :fetch-suggestions="querySearchAsync"
                        value-key="ch_name"
                        placeholder="请输入专业"
                        clearable
                        @select="handleChange"
                        @change="handleChange"

                    ></el-autocomplete>
                        <!-- <el-input v-model="ruleForm.professional" clearable maxlength="50" placeholder="专业" /> -->
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item
                      v-if="isQualifying"
                        label="是否符合STEM学科："
                        prop="match_listing"
                        label-width="auto"
                    >
                        <el-radio-group  v-model="ruleForm.match_listing" >
                            <el-radio size="mini" :label="1">是</el-radio>
                            <el-radio size="mini" :label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-else></el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-row>
                        <el-col :span="13">
                            <el-form-item
                                label="学位："
                                :rules="{ required: true, message: '请选择学位', trigger: 'change' }"
                                prop="background"
                            >
                                <el-select style="width: 100%;" v-model="ruleForm.background" placeholder="请选择">
                                    <el-option
                                        v-for="item in degreeOpts"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11" class="form-item">
                            <el-form-item
                                prop="degree_type"
                            >
                                <el-select style="width: 100%;" v-model="ruleForm.degree_type" placeholder="请选择学位类型">
                                    <el-option
                                        v-for="item in degreeTypes"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="12">
                    <el-row>
                        <el-col :span="15">
                            <el-form-item
                                label="上课地点："
                                prop="school_address.country"
                            >
                                <CountrySelect v-model="ruleForm.school_address.country" placeholder="输入匹配国家及地区" @input="handleSelect" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="9">
                            <el-form-item
                                class="form-item"
                                v-if="ruleForm.school_address.country.indexOf('中国') !== -1 || ruleForm.school_address.country === ''"
                                prop="school_address.area"
                            >
                                <CitySelect :country="ruleForm.school_address.country" v-model="ruleForm.school_address.area"  style="width: 100%;" />
                            </el-form-item>
                            <el-form-item
                                class="form-item"
                                prop="school_address.foreign"
                                v-else
                            >
                                <el-input v-model="ruleForm.school_address.foreign" clearable placeholder="请输入城市" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
       

                <el-col :span="24">
                    <el-form-item
                        label="您是否可以提供以下学历证明文件："
                        prop="cert_confirm"
                        label-width="auto"
                        :rules="{
                            required: true,
                            trigger: 'change',
                            validator:validateCertConfirm
                        }"
                    >
                        <el-radio-group v-model="ruleForm.cert_confirm">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="2">否</el-radio>
                        </el-radio-group>
                        <div style="margin-left:12px;color:#666;line-height:24px">
                            <p>国内院校需提供：学位证+毕业证+学位认证报告</p>
                            <p>国外院校需提供：学位证+成绩单+留服认证报告</p>
                        </div>
                    </el-form-item>

                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import { stemList } from '@/api/gifted'
export default {
    props: {
        data: {
            type: Object,
            default:() => {}
        },
        qualifyingUniversity: {
          type: Array,
          default() {
            return []
          }
        }
    },
    data() {
        return {
            ruleForm: {},
            options: [
                { value: '全日制', label: '全日制' },
                { value: '兼读制', label: '兼读制' },
                { value: '全日制（遥距课程）', label: '全日制（遥距课程）' },
                { value: '兼读制（遥距课程）', label: '兼读制（遥距课程）' }
            ],
            degreeOpts: [
                { value: '无学位（专科）', label: '无学位（专科）' },
                { value: '无学位（本科）', label: '无学位（本科）' },
                { value: "无学位（硕士）", label: "无学位（硕士）" },
                { value: "无学位（博士）", label: "无学位（博士）" },
                { value: '学士学位', label: '学士学位' },
                { value: '硕士学位', label: '硕士学位' },
                { value: '博士学位', label: '博士学位' },
            ],
            degreeTypes: [
                { value: '会计/金融', label: '会计/金融' },
                { value: '艺术及人文/文学', label: '艺术及人文/文学' },
                { value: '工商管理', label: '工商管理' },
                { value: '经济学', label: '经济学' },
                { value: '工程学', label: '工程学' },
                { value: '资讯科技', label: '资讯科技' },
                { value: '电子及软件工程/数据科学', label: '电子及软件工程/数据科学' },
                { value: '法律', label: '法律' },
                { value: '生命科学及医学', label: '生命科学及医学' },
                { value: '管理学', label: '管理学' },
                { value: '理学', label: '理学' },
                { value: '社会科学', label: '社会科学' },
                { value: '其他', label: '其他' },
            ],
        }
    },
    computed: {
      isQualifying() {
        return this.qualifyingUniversity.some(item=>{
                return item.name === this.ruleForm.college || item.alias === this.ruleForm.college
            })
      }
    },
    created() {
        this.ruleForm = this.data
        this.getStemList()
        this.$set(this.ruleForm, 'pickerAdmissionTime', {
            disabledDate(time) {
                return time.getTime() >= Date.now() - 8.64e6;
            }
        })
        this.$set(this.ruleForm, 'pickerGraduateTime', {
            disabledDate(time) {
                const admission_time = this.ruleForm.admission_time
                if (admission_time) {
                    const date = this.$dayjs(admission_time).valueOf()
                    return time.getTime() <= date
                }
            }
        })

    },
    methods: {
        // 获取 stem 列表
        getStemList(){
            stemList().then((res)=>{
                if(res.code === 200){
                    this.stemList = res.data
                }
            })
        },

        querySearchAsync(str, cb){
            const results = str ? this.stemList.filter((item)=>{
                return (item.ch_name).includes(str)
            }) : this.stemList

            cb(results)
        },


        handleChange(value){
            let v = value
            if(typeof value === 'object'){
                v = value.ch_name
            }
            const item = this.stemList.find(item=>{
                return item.ch_name === v
            })
            this.ruleForm.match_listing = item ? 1 : 0
        },

        // 校验参数
        checkParm() {
            const isCheck = Promise.all([this.$refs['ruleForm'].validate()])
            return isCheck
        },

        // 输入匹配
        handleSelect(v) {
            if (v.indexOf('中国') !== -1) {
                this.ruleForm.school_address_aboard = 0
            } else {
                this.ruleForm.school_address_aboard = 1
            }
            this.ruleForm.school_address.area = []
            this.ruleForm.school_address.foreign = ''
        },

        // 毕业
        handleAdmissionChange(v) {
            if (v) {
                const date = this.$dayjs(v).valueOf()
                this.ruleForm.pickerGraduateTime = {
                    disabledDate(time) {
                        return time.getTime() <= date
                    }
                }
            }
            //  else {
            //     this.ruleForm.pickerGraduateTime = {
            //         disabledDate(time) {
            //             return time.getTime() >= Date.now() - 8.64e6;
            //         }
            //     }
            // }
        },

        // 入学
        handleGraduateChange(v) {
            if (v) {
                const date = this.$dayjs(v).valueOf()
                this.ruleForm.pickerAdmissionTime = {
                    disabledDate(time) {
                        return time.getTime() > date
                    }
                }
            } else {
                this.ruleForm.pickerAdmissionTime = {
                    disabledDate(time) {
                        return time.getTime() >= Date.now() - 8.64e6;
                    }
                }
            }
        },

        validateCertConfirm(rule,value,callback){
            if(value!== 1 && value !== 2 ){
                callback(new Error('请选择'))
            } else {
                callback()
            }
        }
    },
}
</script>

<style lang="less" scoped>
.form {
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}
.form-item {
    /deep/ .el-form-item__content {
        margin-left: 0 !important;
    }
}
</style>
