import { render, staticRenderFns } from "./EditSpouse.vue?vue&type=template&id=53a9fb65&scoped=true&"
import script from "./EditSpouse.vue?vue&type=script&lang=js&"
export * from "./EditSpouse.vue?vue&type=script&lang=js&"
import style0 from "./EditSpouse.vue?vue&type=style&index=0&id=53a9fb65&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53a9fb65",
  null
  
)

export default component.exports