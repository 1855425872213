<!--
 * @Author: filename
 * @Description: 学历信息
-->
<template>
    <div v-loading="isLoading">
        <div v-for="(item, i) in data.education" :key="i" :id="'pdf-education'+i">
            <div :class="['title-box', i > 0 ? 'mt-20' : '']" :id="'education'+i">
                <span>
                    学历{{ i+1 }}/{{data.education.length}}
                    <template v-if="item.show_title">
                        (<i style="color: red;">{{item.show_title}}</i>)
                    </template>
                </span>
                <div class="title-btn">
                    <!-- 显示状态下按钮 -->
                    <template v-if="!item.isEdit">
                        <el-popconfirm v-if="data.education.length > 1" placement="top" title="确定删除？" @confirm="del(i)">
                            <el-button slot="reference">删除</el-button>
                        </el-popconfirm>
                        &nbsp;
                        <el-button type="primary" @click="editInfo(item)">编辑</el-button>
                    </template>
                    <!-- 编辑状态下按钮 -->
                    <template v-else>
                        <el-button :loading="item.isLoading" @click="cancel(item, i)">取消</el-button>
                        <el-button type="primary" :loading="item.isLoading" @click="checkInfo(i)">保存</el-button>
                    </template>
                </div>
            </div>
            <!-- 显示 -->
            <EducationDetail v-if="!item.isEdit" :data="item" :qualifyingUniversity="qualifyingUniversity" />
            <!-- 编辑 -->
            <EditEducation v-loading="item.isLoading" :ref="'education'+i" v-if="item.isEdit" :data="education[i]" :qualifyingUniversity="qualifyingUniversity" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import EducationDetail from './C/EducationDetail.vue'
import EditEducation from './C/EditEducation.vue'
import { postClientInfo } from '../../../../../../api/gifted'
import {  getQualifyingUniversity } from '@/api/gifted'


export default {
    props: {
        data: {
            type: Object,
            default:() => {}
        },
    },
    components: {
        EducationDetail,
        EditEducation
    },
    data() {
        return {
            qualifyingUniversity: [],
            isLoading: false
        }
    },
    computed: {
        ...mapState('previewPdfGifted', ['education'])
    },
    created() {
        this.getQualifyingUniversityData()
        this.setIsEdit();
        // pdf预览逻辑
        this.$store.dispatch('previewPdfGifted/setStateKeyData', { key: 'education', data: JSON.parse(JSON.stringify(this.data.education)) })
    },
    methods: {
      async getQualifyingUniversityData() {
         const res = await getQualifyingUniversity()
         if (res.code === 200) {
          this.qualifyingUniversity = res.data
         }
       },
        // set isEdit
        setIsEdit() {
            this.data.education.forEach((item, i) => {
                this.$set(this.data.education[i], 'isEdit', false)
                this.$set(this.data.education[i], 'isLoading', false)
            })
        },
        pdfEducation(){
            this.$store.dispatch('previewPdfGifted/setStateKeyData', { key: 'education', data: JSON.parse(JSON.stringify(this.data.education)) })
        },
        // 取消
        cancel(item, index) {
            this.$store.dispatch('previewPdfGifted/setStateKeyData', { key: 'education', data: JSON.parse(JSON.stringify(this.data.education)) })
            if (item.id) {
                item.isEdit = false
            } else {
                this.data.education.splice(index, 1)
            }
            this.$utils.emptyModuleRed(this, 'pdf-bg7')
        },
        // 保存
        async saveInfo(index) {
            try {
                this.data.education[index].isLoading = true
                const parm = JSON.parse(JSON.stringify(this.data))
                parm.app = 2
                parm.education[index] = this.$refs['education'+index][0].ruleForm
                const { code, data } = await postClientInfo(parm)
                if (code === 200) {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    })
                    this.data.education[index].isEdit = false
                    this.data.education = data.education
                    this.data.component_score = data.component_score
                    this.setIsEdit()
                    this.$utils.saveGiftedStatus(this.$route.params.id)
                    this.$store.dispatch('previewPdfGifted/setStateKeyData', { key: 'education', data: JSON.parse(JSON.stringify(this.data.education)) })
                }
            } finally {
                this.data.education[index].isLoading = false
            }
        },
        // 编辑
        editInfo(item) {
            item.isEdit = true
        },
        // 保存
        checkInfo(index) {
            Promise.all([this.$refs['education'+index][0].checkParm()])
            .then(async () => {
                this.saveInfo(index)
            })
            .catch((err) => {
                console.log(err)
                setTimeout(() => {
                    this.$utils.scrollIntoError(this)
                }, 100)
            })
            this.$utils.emptyModuleRed(this, 'pdf-bg7')
        },
        // 删除
        async del(index) {
            try {
                this.isLoading = true
                this.data.education.splice(index, 1)
                const { code, data } = await postClientInfo(this.data)
                if (code === 200) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    })
                    this.data.component_score = data.component_score
                    this.$store.dispatch('previewPdfGifted/setStateKeyData', { key: 'education', data: JSON.parse(JSON.stringify(this.data.education)) })
                    this.$utils.saveGiftedStatus(this.$route.params.id)
                }
            } finally {
                this.isLoading = false
            }
        },
        // 新增锚点到新增相应的位置
        anchorPoint() {
            this.$nextTick(() => {
                const pageId = document.querySelector('#education' + (this.data.education.length - 1))
                pageId.scrollIntoView({
                    block: "start",
                    behavior: "smooth",
                })
            })
        }
    }
}
</script>

<style lang="less" scoped>

</style>
